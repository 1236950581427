html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }

.custom-gradient-1 {
    background: linear-gradient(to bottom, transparent, transparent 2%, #040814 90%);
  }

.custom-gradient-2 {
    background: linear-gradient(to bottom, #040814 2%, transparent 50%, transparent);
}

.custom-gradient-bottom {
    background: linear-gradient(to bottom, transparent 50%, transparent, #040814);
    background-position: bottom;
}

.custom-gradient-3 {
    background: linear-gradient(to bottom, #040814 12%, transparent 60%, transparent);
}

.custom-gradient-4 {
    background: linear-gradient(to bottom, transparent 30%, white 45%, #040814 75%);
}

@media (min-width: 768px) {
    .custom-gradient-1 {
      background: linear-gradient(to bottom, transparent, transparent 2%, #040814 65%);
    }
    .custom-gradient-2 {
        background: linear-gradient(
            to bottom,
            #040814 0.5%,             /* Color inicial (oscuro) */
            #3b3b3b 10%,              /* Gris oscuro */
            #7f7f7f 25%,              /* Gris intermedio */
            rgba(255, 255, 255, 0.7) 48%, /* Blanco semitransparente */
            rgba(255, 255, 255, 0.5) 70%, /* Blanco semitransparente */
            transparent 80%           /* Fin completamente transparente */
        );
    }
    .custom-gradient-bottom {
        background: linear-gradient(to bottom, transparent, transparent 60%, #0408147e 78%, #040814b7 85%, #040814 98%);
        background-position: bottom;
    }

    .custom-gradient-3 {
        background: linear-gradient(to bottom, #040814 23%, #040814b0, transparent 80%, transparent);
    }

    .custom-gradient-4 {
        background: linear-gradient(to bottom, transparent 30%, white 40%,#0408149c, #040814 75%);
    }
  }

/* Flechas de navegación */
.swiper-button-next,
.swiper-button-prev {
  color: white; /* Cambia el color de las flechas a blanco */
  background-color: rgba(0, 0, 0, 0.4); /* Fondo semi-transparente */
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

/* Ajustar el tamaño de las flechas */
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
}

/* Puntos de paginación */
.swiper-pagination-bullet {
  background-color: white; /* Cambia el color de los puntos a blanco */
  opacity: 0.7; /* Reduce un poco la opacidad */
}

.swiper-pagination-bullet-active {
  background-color: white; /* Asegura que el punto activo también sea blanco */
  opacity: 1; /* Opacidad completa para el punto activo */
}